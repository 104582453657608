import { FC } from "react";
import { Image } from "react-datocms/image";
import { ResponsiveImage } from "services/datocms/generated";

type CategoryHeroProps = {
  heroText?: string | null;
  description?: string | null;
  heroImage?: ResponsiveImage | null;
  heroImageMobile?: ResponsiveImage | null;
};

export const CategoryHero: FC<CategoryHeroProps> = ({
  heroImage,
  heroImageMobile,
  description,
  heroText,
}) => {
  const hasImages = heroImage || heroImageMobile;
  const showHeroCopy = heroText || description;
  const showHeroSection = hasImages || showHeroCopy;

  return (
    <>
      {showHeroSection && (
        <div className="relative">
          {heroImage && <Image className="hidden md:block" data={heroImage} />}
          {heroImageMobile && (
            <Image className="md:hidden" data={heroImageMobile} />
          )}
          {showHeroCopy && (
            <div
              className={`${
                hasImages && "absolute"
              } inset-0 flex justify-start items-center p-6`}
            >
              <div className={`${hasImages && "md:w-1/2"} w-full text-primary`}>
                {description && (
                  <span className="block md:text-center text-sm md:text-xl font-semibold">
                    {description}
                  </span>
                )}
                {heroText && (
                  <h1 className="md:text-center font-extrabold text-2xl sm:text-4xl md:text-7xl">
                    {heroText}
                  </h1>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
