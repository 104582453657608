import { FC } from "react";
import {
  ProductCategoryFragment,
  DatoProductForCategoryPageFragment,
} from "services/datocms/generated";
import { useGetItemsByGtinQuery } from "services/graphql/generated";
import { BottleListing } from "./BottleListing";
import { FlavorsListing } from "./FlavorsListing";
import { MachineListing } from "./MachineListing";

interface ProductListingProps {
  category: ProductCategoryFragment;
  products: DatoProductForCategoryPageFragment[];
}

const ProductListing: FC<ProductListingProps> = ({ category, products }) => {
  const { data: allPepdirectItemsQuery, loading } = useGetItemsByGtinQuery({
    variables: { gtins: products.map((product) => product.gtin ?? "") },
  });

  const pepdirectProducts = allPepdirectItemsQuery?.items?.items;

  const renderListingBasedOnCategory = () => {
    switch (category?.style) {
      case "style2":
        return (
          <MachineListing
            products={products}
            category={category}
            pepdirectProducts={pepdirectProducts}
            loading={loading}
          />
        );
      case "style4":
        return (
          <BottleListing
            products={products}
            category={category}
            pepdirectProducts={pepdirectProducts}
            loading={loading}
          />
        );
      default:
        return (
          <FlavorsListing
            products={products}
            category={category}
            pepdirectProducts={pepdirectProducts}
            loading={loading}
          />
        );
    }
  };

  return renderListingBasedOnCategory();
};

export default ProductListing;
