import cx from "classnames";
import { NewButton } from "components/NewButton/NewButton";
import Link from "next/link";
import { FC } from "react";
import { StructuredText } from "react-datocms";
import { Image } from "react-datocms/image";
import { PromotionalCardFragment } from "services/datocms/generated";
import st from "./single-product.module.scss";

type PromotionalCardProps = {
  cardData: PromotionalCardFragment;
};

export const PromotionalCard: FC<PromotionalCardProps> = ({ cardData }) => {
  const { image, button, text, url } = cardData;

  const buttonStyle: React.CSSProperties = {};

  if (button?.backgroundColor) {
    buttonStyle.backgroundColor = button?.backgroundColor.hex;
  }
  if (button?.textColor) {
    buttonStyle.color = button.textColor.hex;
  }

  return (
    <div className={cx(st.item, "w-full relative !p-0")}>
      <Link href={url ?? ""}>
        {image?.responsiveImage && (
          <Image
            className="z-0"
            objectFit="cover"
            layout="fill"
            data={image?.responsiveImage}
          />
        )}
        {text?.text && (
          <div
            className={cx(
              "absolute flex flex-col gap-4 p-6 w-full text-2xl",
              text.verticalPosition === "center" && "top-1/2 -translate-y-1/2",
              text.verticalPosition === "bottom" && "bottom-0",
              text.horizontalPosition === "center" && "text-center",
              text.horizontalPosition === "right" && "text-right",
            )}
            style={{ color: text.color?.hex }}
          >
            <StructuredText data={text.text.value} />
          </div>
        )}
        {button && button.buttonText && (
          <div
            className={cx(
              "absolute flex p-6 w-full",
              button?.verticalPosition === "center" &&
                "top-1/2 -translate-y-1/2",
              button?.verticalPosition === "bottom" && "bottom-0",
              button?.horizontalPosition === "left" && "justify-start",
              button?.horizontalPosition === "center" && "justify-center",
              button?.horizontalPosition === "right" && "justify-end",
            )}
          >
            <NewButton style={buttonStyle}>{button?.buttonText}</NewButton>
          </div>
        )}
      </Link>
    </div>
  );
};
