import {
  getEventProduct,
  logProductListImpressionEvent,
  logSelectItemEvent,
} from "helpers/analyticsLogger";
import { useEffect, useMemo } from "react";
import { DatoProductForCategoryPageFragment } from "services/datocms/generated";
import {
  DynamicBundlesQuery,
  GetItemsByGtinQuery,
} from "services/graphql/generated";

export const useFlavorsAndBottlesAnalytics = (
  currency: string,
  analyticsName: string | null | undefined,
  analyticsCategoryName: string | null | undefined,
  products: DatoProductForCategoryPageFragment[],
  pepdirectProducts?: NonNullable<GetItemsByGtinQuery["items"]>["items"],
  allDynamicBundles?: DynamicBundlesQuery | undefined,
) => {
  const itemListName = `Collection - ${analyticsName}`;
  const listingItemsForLogging = useMemo(() => {
    return products.map((product, index) => {
      const additionalData = pepdirectProducts?.find(
        (item) => item.gtin === product?.gtin,
      );

      let bundle;
      if (product.bundleTag) {
        bundle = allDynamicBundles?.dynamicBundles?.find(
          (bundle) => bundle?.tag === product.bundleTag,
        );
      }

      return getEventProduct(additionalData, bundle, {
        itemCategory: analyticsCategoryName,
        itemListName: itemListName,
        currency,
        index: ++index,
      });
    });
  }, [
    analyticsCategoryName,
    currency,
    itemListName,
    pepdirectProducts,
    products,
    allDynamicBundles,
  ]);

  useEffect(() => {
    if (currency && pepdirectProducts) {
      if (!allDynamicBundles) {
        logProductListImpressionEvent(
          itemListName,
          currency,
          listingItemsForLogging,
        );
      } else if (allDynamicBundles && allDynamicBundles.dynamicBundles) {
        logProductListImpressionEvent(
          itemListName,
          currency,
          listingItemsForLogging,
        );
      }
    }
  }, [
    currency,
    itemListName,
    listingItemsForLogging,
    pepdirectProducts,
    allDynamicBundles,
  ]);

  const handleLogging = (itemId: string) => {
    const itemToLog = listingItemsForLogging.find(
      (loggedItem) => loggedItem.itemId === itemId,
    );

    if (itemToLog) {
      logSelectItemEvent(itemListName, currency, [
        { ...itemToLog, index: itemToLog.index },
      ]);
    }
  };

  return { handleLogging };
};
