import { PartialProduct } from "@shared/types";
import { ITEM_BRAND } from "config";
import {
  logProductListImpressionEvent,
  logSelectItemEvent,
} from "helpers/analyticsLogger";
import { useCallback, useEffect, useMemo } from "react";
import {
  DatoProductForCategoryPageFragment,
  ProductCategoryFragment,
} from "services/datocms/generated";
import { DynamicBundlesQuery } from "services/graphql/generated";

export const useMachineListingAnalytics = (
  currency: string | undefined,
  category: NonNullable<ProductCategoryFragment>,
  products: DatoProductForCategoryPageFragment[],
  allDynamicBundles?: DynamicBundlesQuery | undefined,
) => {
  const itemsForLogging: PartialProduct[] = useMemo(() => {
    if (products && currency && allDynamicBundles && category) {
      const pepDirectProductBundles: NonNullable<
        DynamicBundlesQuery["dynamicBundles"]
      > = [];
      products.forEach((product) => {
        product.productBundles.forEach((bundle) => {
          const pepBundle = allDynamicBundles?.dynamicBundles?.find(
            (item) => item?.tag === bundle.bundleId,
          );
          if (pepBundle) {
            pepDirectProductBundles.push(pepBundle);
          }
        });
      });

      const itemListName = `Collection - ${category?.analyticsName}`;
      const items = pepDirectProductBundles?.map((bundle, index) => {
        const price = bundle?.discountPrice ? bundle?.discountPrice / 100 : 0;
        return {
          itemId: bundle?.id,
          gtin: bundle?.primaryComponent.items?.[0]?.gtin,
          itemName: bundle?.name,
          itemBrand: ITEM_BRAND,
          itemCategory: category.analyticsCategoryName,
          price: price,
          itemListName,
          currency,
          quantity: 1,
          index: index + 1,
        };
      });

      return items;
    }
    return [];
  }, [products, currency, allDynamicBundles, category]);

  useEffect(() => {
    if (currency && itemsForLogging.length > 0) {
      logProductListImpressionEvent(
        itemsForLogging[0].itemListName ?? "",
        currency,
        itemsForLogging,
      );
    }
  }, [currency, itemsForLogging]);

  const handleLogging = useCallback(
    (id: string) => {
      const datoProduct = products.find((product) => product.id === id);
      const bundlesForItem = datoProduct?.productBundles.map(
        ({ bundleId }) =>
          allDynamicBundles?.dynamicBundles?.find(
            (bundle) => bundle?.tag === bundleId,
          ),
      );
      const items = bundlesForItem?.map((bundle) => {
        return itemsForLogging.find(
          (item) => item.itemId === bundle?.id,
        ) as PartialProduct;
      });
      if (items) {
        logSelectItemEvent(items[0]?.itemListName ?? "", currency, items);
      }
    },
    [allDynamicBundles?.dynamicBundles, currency, itemsForLogging, products],
  );

  return { handleLogging };
};
