import { PAGE_TYPE_ENUM } from "@shared/types";
import ProductListing from "components/ProductListing/ProductListing";
import { DatoComponentsWrapper } from "components/common/DatoComonentsWrappeer/DatoComponentsWrapper";
import PageLayout from "components/layout/PageLayout";
import { setDYContext } from "config";
import { useLogContext } from "context/log";
import { generatePageId } from "helpers/analyticsLogger";
import { FC, useEffect } from "react";
import {
  ComponentsWrapperFragment,
  ProductCategoryFragment,
  DatoProductForCategoryPageFragment,
} from "services/datocms/generated";

interface CategoryPageProps {
  products?: DatoProductForCategoryPageFragment[];
  category: ProductCategoryFragment;
  components?: ComponentsWrapperFragment;
}

const Category: FC<CategoryPageProps> = ({
  products,
  category,
  components,
}) => {
  const { useLogPageView } = useLogContext();

  useLogPageView({
    pageType: PAGE_TYPE_ENUM.collection,
    id: generatePageId("category"),
  });

  useEffect(() => {
    if (category?.analyticsName) {
      setDYContext("CATEGORY", [category?.analyticsName || ""]);
    }
  }, [category?.analyticsName]);

  return (
    <PageLayout
      seo={category?.seo}
      navigationOverlapsContent={category?.style === "style2"}
    >
      {products && <ProductListing category={category} products={products} />}
      <DatoComponentsWrapper components={components} />
    </PageLayout>
  );
};

export default Category;
