import cx from "classnames";
import { MachineComparison } from "components/MachineComparison";
import { AppContext } from "context/app";
import { useMachineListingAnalytics } from "hooks/analytics/useMachineListingAnalytics";
import { FC, useContext, useMemo } from "react";
import { Image } from "react-datocms/image";
import {
  DatoProductForCategoryPageFragment,
  ProductCategoryFragment,
} from "services/datocms/generated";
import {
  GetItemsByGtinQuery,
  useDynamicBundlesQuery,
} from "services/graphql/generated";
import { MachineProduct } from "./MachineProduct";
import st from "./product-listing.module.scss";

interface MachineListingProps {
  category: NonNullable<ProductCategoryFragment>;
  products: NonNullable<DatoProductForCategoryPageFragment[]>;
  pepdirectProducts?: NonNullable<GetItemsByGtinQuery["items"]>["items"];
  loading?: boolean;
}

export const MachineListing: FC<MachineListingProps> = ({
  products,
  category,
  pepdirectProducts,
  loading = false,
}) => {
  const { data: allDynamicBundles } = useDynamicBundlesQuery();
  const { tenant } = useContext(AppContext);
  const currency = tenant?.checkout?.paymentOptions?.defaultCurrencyCode;
  const { heroImage, heroImageMobile, heroText, description } = category;

  const { handleLogging } = useMachineListingAnalytics(
    currency,
    category,
    products,
    allDynamicBundles,
  );

  const productsWithoutVariants = useMemo(() => {
    const renderedColorVariants: Set<string | null | undefined> = new Set();
    return products.filter((product) => {
      if (!renderedColorVariants.has(product.slug)) {
        product.colorVariants.forEach((color) =>
          renderedColorVariants.add(color.slug),
        );
        return true;
      }
      return false;
    });
  }, [products]);

  const productIdsSlugs = products.map((prod) => {
    return {
      id: prod.id,
      slug: prod.slug || "",
    };
  });

  const hasImages = heroImage || heroImageMobile;
  const showHeroSection = hasImages || heroText;

  return (
    <div className={st.machineContainer}>
      {showHeroSection && (
        <div className="relative">
          {heroImage?.responsiveImage && (
            <Image
              className="hidden md:block"
              data={heroImage.responsiveImage}
            />
          )}
          {heroImageMobile?.responsiveImage && (
            <Image
              className="md:hidden"
              data={heroImageMobile.responsiveImage}
            />
          )}
          {heroText && (
            <div
              className={`${
                hasImages && "absolute"
              } inset-0 flex justify-start items-center p-6`}
            >
              <div className={`${hasImages && "md:w-1/2"} w-full text-primary`}>
                {heroText && (
                  <p className="md:text-center font-extrabold text-2xl sm:text-4xl md:text-7xl">
                    {heroText}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {description && <h1 className={st.description}>{description}</h1>}
      <div className={cx(st.listingContainer, "container")}>
        {productsWithoutVariants.map((product) => {
          return (
            <MachineProduct
              key={product.gtin}
              datoProduct={product}
              pepdirectProduct={pepdirectProducts?.find(
                (item) => item.gtin === product?.gtin,
              )}
              handleLogging={handleLogging}
              productIdsSlugs={productIdsSlugs}
              loading={loading}
            />
          );
        })}
      </div>
      <MachineComparison
        products={productsWithoutVariants}
        pepdirectProducts={pepdirectProducts}
      />
    </div>
  );
};
