import ChevronDownSvg from "components/svg/ChevronDownSvg";
import { FC, useState } from "react";

interface MachineComparisonData {
  howItWorks: string;
  howToFizz: string;
  cylinderInsertion: string;
  bottleMaterial: string;
  dishwasherSafeBottles: string;
  compatibleBottles: string;
  dimensions: string;
}

interface Maker {
  color: { hex: string }[];
  data: MachineComparisonData;
  image: string;
  description: string;
  slug: string;
  gtin: string;
}

type WaterMakersDropdownProps = {
  makers: Maker[];
  selectedMaker: Maker;
  onSelectMaker: (maker: Maker) => void;
};

const WaterMakersDropdown: FC<WaterMakersDropdownProps> = ({
  makers,
  selectedMaker,
  onSelectMaker,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  return (
    <div
      tabIndex={0}
      onBlur={() => setDropdownVisible(false)}
      className="relative cursor-pointer text-center shadow-[0_1px_10px_#00000012] border-[1px] border-[#e5dcd3] rounded-md px-4"
      onClick={() => setDropdownVisible((prevVisible) => !prevVisible)}
    >
      <div className="text-xl font-semibold px-5 py-2 flex justify-center">
        <div className="mx-auto">{selectedMaker.slug.toUpperCase()}</div>
        <div className="self-center">
          <ChevronDownSvg />
        </div>
      </div>
      <div
        className={`absolute left-0 w-full top-[50px] bg-white shadow-[0_2px_88px_rgba(0,0,0,.2);] divide-y ${
          dropdownVisible ? "block" : "hidden"
        }`}
      >
        {makers.map((maker, idx) => {
          return (
            <div
              className="p-4 text-center font-semibold text-sm hover:bg-[#e9ecef]"
              key={idx}
              onClick={() => onSelectMaker(maker)}
            >
              {maker.slug.toUpperCase()}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WaterMakersDropdown;
